import "core-js/modules/es.array.find.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.parse-float.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.search.js";
import $ from "jquery";
import { InputBatchSender, InputDeferDecorator, InputEventDecorator, InputNoResendDecorator, InputRateDecorator, InputValidateDecorator } from "../inputPolicies";
import { addDefaultInputOpts } from "../inputPolicies/inputValidateDecorator";
import { debounce, Debouncer } from "../time";
import { getComputedLinkColor, getStyle, hasOwnProperty, mapValues, pixelRatio } from "../utils";
import { bindAll, unbindAll, _bindAll } from "./bind";
import { setShinyObj } from "./initedMethods";
import { registerDependency, renderHtml } from "./render";
import { sendImageSizeFns } from "./sendImageSize";
import { ShinyApp } from "./shinyapp";
import { registerNames as singletonsRegisterNames } from "./singletons";

// "init_shiny.js"
function initShiny(windowShiny) {
  setShinyObj(windowShiny);
  var shinyapp = windowShiny.shinyapp = new ShinyApp();
  windowShiny.progressHandlers = shinyapp.progressHandlers;
  var inputBatchSender = new InputBatchSender(shinyapp);
  var inputsNoResend = new InputNoResendDecorator(inputBatchSender);
  var inputsEvent = new InputEventDecorator(inputsNoResend);
  var inputsRate = new InputRateDecorator(inputsEvent);
  var inputsDefer = new InputDeferDecorator(inputsEvent);
  var target;

  if ($('input[type="submit"], button[type="submit"]').length > 0) {
    // If there is a submit button on the page, use defer decorator
    target = inputsDefer;
    $('input[type="submit"], button[type="submit"]').each(function () {
      $(this).click(function (event) {
        event.preventDefault();
        inputsDefer.submit();
      });
    });
  } else {
    // By default, use rate decorator
    target = inputsRate;
  }

  var inputs = new InputValidateDecorator(target);

  windowShiny.setInputValue = windowShiny.onInputChange = function (name, value) {
    var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var newOpts = addDefaultInputOpts(opts);
    inputs.setInput(name, value, newOpts);
  }; // By default, Shiny deduplicates input value changes; that is, if
  // `setInputValue` is called with the same value as the input already
  // has, the call is ignored (unless opts.priority = "event"). Calling
  // `forgetLastInputValue` tells Shiny that the very next call to
  // `setInputValue` for this input id shouldn't be ignored, even if it
  // is a dupe of the existing value.


  windowShiny.forgetLastInputValue = function (name) {
    inputsNoResend.forget(name);
  }; // MUST be called after `setShiny()`


  var inputBindings = windowShiny.inputBindings;
  var outputBindings = windowShiny.outputBindings;

  function shinyBindCtx() {
    return {
      inputs: inputs,
      inputsRate: inputsRate,
      sendOutputHiddenState: sendOutputHiddenState,
      maybeAddThemeObserver: maybeAddThemeObserver,
      inputBindings: inputBindings,
      outputBindings: outputBindings,
      initDeferredIframes: initDeferredIframes
    };
  }

  windowShiny.bindAll = function (scope) {
    bindAll(shinyBindCtx(), scope);
  };

  windowShiny.unbindAll = function (scope) {
    var includeSelf = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    unbindAll(shinyBindCtx(), scope, includeSelf);
  }; // Calls .initialize() for all of the input objects in all input bindings,
  // in the given scope.


  function initializeInputs() {
    var scope = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document.documentElement;
    var bindings = inputBindings.getBindings(); // Iterate over all bindings

    for (var i = 0; i < bindings.length; i++) {
      var binding = bindings[i].binding;
      var inputObjects = binding.find(scope);

      if (inputObjects) {
        // Iterate over all input objects for this binding
        for (var j = 0; j < inputObjects.length; j++) {
          var $inputObjectJ = $(inputObjects[j]);

          if (!$inputObjectJ.data("_shiny_initialized")) {
            $inputObjectJ.data("_shiny_initialized", true);
            binding.initialize(inputObjects[j]);
          }
        }
      }
    }
  }

  windowShiny.initializeInputs = initializeInputs;

  function getIdFromEl(el) {
    var $el = $(el);
    var bindingAdapter = $el.data("shiny-output-binding");
    if (!bindingAdapter) return null;else return bindingAdapter.getId();
  } // Initialize all input objects in the document, before binding


  initializeInputs(document.documentElement); // The input values returned by _bindAll() each have a structure like this:
  //   { value: 123, opts: { ... } }
  // We want to only keep the value. This is because when the initialValues is
  // passed to ShinyApp.connect(), the ShinyApp object stores the
  // initialValues object for the duration of the session, and the opts may
  // have a reference to the DOM element, which would prevent it from being
  // GC'd.

  var initialValues = mapValues(_bindAll(shinyBindCtx(), document.documentElement), function (x) {
    return x.value;
  }); // When future bindings are registered via dynamic UI, check to see if renderHtml()
  // is currently executing. If it's not, it's likely that the binding registration
  // is occurring a tick after renderHtml()/renderContent(), in which case we need
  // to make sure the new bindings get a chance to bind to the DOM. (#3635)

  var maybeBindOnRegister = debounce(0, function () {
    if (!renderHtml.isExecuting()) {
      windowShiny.bindAll(document.documentElement);
    }
  });
  inputBindings.onRegister(maybeBindOnRegister, false);
  outputBindings.onRegister(maybeBindOnRegister, false); // The server needs to know the size of each image and plot output element,
  // in case it is auto-sizing

  $(".shiny-image-output, .shiny-plot-output, .shiny-report-size").each(function () {
    var id = getIdFromEl(this);

    if (this.offsetWidth !== 0 || this.offsetHeight !== 0) {
      initialValues[".clientdata_output_" + id + "_width"] = this.offsetWidth;
      initialValues[".clientdata_output_" + id + "_height"] = this.offsetHeight;
    }
  });

  function getComputedBgColor(el) {
    if (!el) {
      // Top of document, can't recurse further
      return null;
    }

    var bgColor = getStyle(el, "background-color");
    var m = bgColor.match(/^rgba\(\s*([\d.]+)\s*,\s*([\d.]+)\s*,\s*([\d.]+)\s*,\s*([\d.]+)\s*\)$/);

    if (bgColor === "transparent" || m && parseFloat(m[4]) === 0) {
      // No background color on this element. See if it has a background image.
      var bgImage = getStyle(el, "background-image");

      if (bgImage && bgImage !== "none") {
        // Failed to detect background color, since it has a background image
        return null;
      } else {
        // Recurse
        return getComputedBgColor(el.parentElement);
      }
    }

    return bgColor;
  }

  function getComputedFont(el) {
    var fontFamily = getStyle(el, "font-family");
    var fontSize = getStyle(el, "font-size");
    return {
      families: fontFamily.replace(/"/g, "").split(", "),
      size: fontSize
    };
  }

  $(".shiny-image-output, .shiny-plot-output, .shiny-report-theme").each(function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var el = this;
    var id = getIdFromEl(el);
    initialValues[".clientdata_output_" + id + "_bg"] = getComputedBgColor(el);
    initialValues[".clientdata_output_" + id + "_fg"] = getStyle(el, "color");
    initialValues[".clientdata_output_" + id + "_accent"] = getComputedLinkColor(el);
    initialValues[".clientdata_output_" + id + "_font"] = getComputedFont(el);
    maybeAddThemeObserver(el);
  }); // Resend computed styles if *an output element's* class or style attribute changes.
  // This gives us some level of confidence that getCurrentOutputInfo() will be
  // properly invalidated if output container is mutated; but unfortunately,
  // we don't have a reasonable way to detect change in *inherited* styles
  // (other than session$setCurrentTheme())
  // https://github.com/rstudio/shiny/issues/3196
  // https://github.com/rstudio/shiny/issues/2998

  function maybeAddThemeObserver(el) {
    if (!window.MutationObserver) {
      return; // IE10 and lower
    }

    var cl = el.classList;
    var reportTheme = cl.contains("shiny-image-output") || cl.contains("shiny-plot-output") || cl.contains("shiny-report-theme");

    if (!reportTheme) {
      return;
    }

    var $el = $(el);

    if ($el.data("shiny-theme-observer")) {
      return; // i.e., observer is already observing
    }

    var observerCallback = new Debouncer(null, function () {
      return doSendTheme(el);
    }, 100);
    var observer = new MutationObserver(function () {
      return observerCallback.normalCall();
    });
    var config = {
      attributes: true,
      attributeFilter: ["style", "class"]
    };
    observer.observe(el, config);
    $el.data("shiny-theme-observer", observer);
  }

  function doSendTheme(el) {
    // Sending theme info on error isn't necessary (it'd add an unnecessary additional round-trip)
    if (el.classList.contains("shiny-output-error")) {
      return;
    }

    var id = getIdFromEl(el);
    inputs.setInput(".clientdata_output_" + id + "_bg", getComputedBgColor(el));
    inputs.setInput(".clientdata_output_" + id + "_fg", getStyle(el, "color"));
    inputs.setInput(".clientdata_output_" + id + "_accent", getComputedLinkColor(el));
    inputs.setInput(".clientdata_output_" + id + "_font", getComputedFont(el));
  }

  function doSendImageSize() {
    $(".shiny-image-output, .shiny-plot-output, .shiny-report-size").each(function () {
      var id = getIdFromEl(this);

      if (this.offsetWidth !== 0 || this.offsetHeight !== 0) {
        inputs.setInput(".clientdata_output_" + id + "_width", this.offsetWidth);
        inputs.setInput(".clientdata_output_" + id + "_height", this.offsetHeight);
      }
    });
    $(".shiny-image-output, .shiny-plot-output, .shiny-report-theme").each(function () {
      doSendTheme(this);
    });
    $(".shiny-bound-output").each(function () {
      var $this = $(this),
          binding = $this.data("shiny-output-binding");
      $this.trigger({
        type: "shiny:visualchange",
        // @ts-expect-error; Can not remove info on a established, malformed Event object
        visible: !isHidden(this),
        binding: binding
      });
      binding.onResize();
    });
  }

  sendImageSizeFns.setImageSend(inputBatchSender, doSendImageSize); // Return true if the object or one of its ancestors in the DOM tree has
  // style='display:none'; otherwise return false.

  function isHidden(obj) {
    // null means we've hit the top of the tree. If width or height is
    // non-zero, then we know that no ancestor has display:none.
    if (obj === null || obj.offsetWidth !== 0 || obj.offsetHeight !== 0) {
      return false;
    } else if (getStyle(obj, "display") === "none") {
      return true;
    } else {
      return isHidden(obj.parentNode);
    }
  }

  var lastKnownVisibleOutputs = {}; // Set initial state of outputs to hidden, if needed

  $(".shiny-bound-output").each(function () {
    var id = getIdFromEl(this);

    if (isHidden(this)) {
      initialValues[".clientdata_output_" + id + "_hidden"] = true;
    } else {
      lastKnownVisibleOutputs[id] = true;
      initialValues[".clientdata_output_" + id + "_hidden"] = false;
    }
  }); // Send update when hidden state changes

  function doSendOutputHiddenState() {
    var visibleOutputs = {};
    $(".shiny-bound-output").each(function () {
      var id = getIdFromEl(this);
      delete lastKnownVisibleOutputs[id]; // Assume that the object is hidden when width and height are 0

      var hidden = isHidden(this),
          evt = {
        type: "shiny:visualchange",
        visible: !hidden
      };

      if (hidden) {
        inputs.setInput(".clientdata_output_" + id + "_hidden", true);
      } else {
        visibleOutputs[id] = true;
        inputs.setInput(".clientdata_output_" + id + "_hidden", false);
      }

      var $this = $(this); // @ts-expect-error; Can not remove info on a established, malformed Event object

      evt.binding = $this.data("shiny-output-binding"); // @ts-expect-error; Can not remove info on a established, malformed Event object

      $this.trigger(evt);
    }); // Anything left in lastKnownVisibleOutputs is orphaned

    for (var name in lastKnownVisibleOutputs) {
      if (hasOwnProperty(lastKnownVisibleOutputs, name)) inputs.setInput(".clientdata_output_" + name + "_hidden", true);
    } // Update the visible outputs for next time


    lastKnownVisibleOutputs = visibleOutputs;
  } // sendOutputHiddenState gets called each time DOM elements are shown or
  // hidden. This can be in the hundreds or thousands of times at startup.
  // We'll debounce it, so that we do the actual work once per tick.


  var sendOutputHiddenStateDebouncer = new Debouncer(null, doSendOutputHiddenState, 0);

  function sendOutputHiddenState() {
    sendOutputHiddenStateDebouncer.normalCall();
  } // We need to make sure doSendOutputHiddenState actually gets called before
  // the inputBatchSender sends data to the server. The lastChanceCallback
  // here does that - if the debouncer has a pending call, flush it.


  inputBatchSender.lastChanceCallback.push(function () {
    if (sendOutputHiddenStateDebouncer.isPending()) sendOutputHiddenStateDebouncer.immediateCall();
  }); // Given a namespace and a handler function, return a function that invokes
  // the handler only when e's namespace matches. For example, if the
  // namespace is "bs", it would match when e.namespace is "bs" or "bs.tab".
  // If the namespace is "bs.tab", it would match for "bs.tab", but not "bs".

  function filterEventsByNamespace(namespace, handler) {
    for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      args[_key - 2] = arguments[_key];
    }

    namespace = namespace.split(".");
    return function (e) {
      var eventNamespace = e.namespace.split("."); // If any of the namespace strings aren't present in this event, quit.

      for (var i = 0; i < namespace.length; i++) {
        if (eventNamespace.indexOf(namespace[i]) === -1) return;
      }

      handler.apply(this, [namespace, handler].concat(args));
    };
  } // The size of each image may change either because the browser window was
  // resized, or because a tab was shown/hidden (hidden elements report size
  // of 0x0). It's OK to over-report sizes because the input pipeline will
  // filter out values that haven't changed.


  $(window).resize(debounce(500, sendImageSizeFns.regular)); // Need to register callbacks for each Bootstrap 3 class.

  var bs3classes = ["modal", "dropdown", "tab", "tooltip", "popover", "collapse"];
  $.each(bs3classes, function (idx, classname) {
    $(document.body).on("shown.bs." + classname + ".sendImageSize", "*", filterEventsByNamespace("bs", sendImageSizeFns.regular));
    $(document.body).on("shown.bs." + classname + ".sendOutputHiddenState " + "hidden.bs." + classname + ".sendOutputHiddenState", "*", filterEventsByNamespace("bs", sendOutputHiddenState));
  }); // This is needed for Bootstrap 2 compatibility and for non-Bootstrap
  // related shown/hidden events (like conditionalPanel)

  $(document.body).on("shown.sendImageSize", "*", sendImageSizeFns.regular);
  $(document.body).on("shown.sendOutputHiddenState hidden.sendOutputHiddenState", "*", sendOutputHiddenState); // Send initial pixel ratio, and update it if it changes

  initialValues[".clientdata_pixelratio"] = pixelRatio();
  $(window).resize(function () {
    inputs.setInput(".clientdata_pixelratio", pixelRatio());
  }); // Send initial URL

  initialValues[".clientdata_url_protocol"] = window.location.protocol;
  initialValues[".clientdata_url_hostname"] = window.location.hostname;
  initialValues[".clientdata_url_port"] = window.location.port;
  initialValues[".clientdata_url_pathname"] = window.location.pathname; // Send initial URL search (query string) and update it if it changes

  initialValues[".clientdata_url_search"] = window.location.search;
  $(window).on("pushstate", function (e) {
    inputs.setInput(".clientdata_url_search", window.location.search);
    return;
    e;
  });
  $(window).on("popstate", function (e) {
    inputs.setInput(".clientdata_url_search", window.location.search);
    return;
    e;
  }); // This is only the initial value of the hash. The hash can change, but
  // a reactive version of this isn't sent because watching for changes can
  // require polling on some browsers. The JQuery hashchange plugin can be
  // used if this capability is important.

  initialValues[".clientdata_url_hash_initial"] = window.location.hash;
  initialValues[".clientdata_url_hash"] = window.location.hash;
  $(window).on("hashchange", function (e) {
    inputs.setInput(".clientdata_url_hash", window.location.hash);
    return;
    e;
  }); // The server needs to know what singletons were rendered as part of
  // the page loading

  var singletonText = initialValues[".clientdata_singletons"] = $('script[type="application/shiny-singletons"]').text();
  singletonsRegisterNames(singletonText.split(/,/));
  var dependencyText = $('script[type="application/html-dependencies"]').text();
  $.each(dependencyText.split(/;/), function (i, depStr) {
    var match = /\s*^(.+)\[(.+)\]\s*$/.exec(depStr);

    if (match) {
      registerDependency(match[1], match[2]);
    }
  }); // We've collected all the initial values--start the server process!

  inputsNoResend.reset(initialValues);
  shinyapp.connect(initialValues);
  $(document).one("shiny:connected", function () {
    initDeferredIframes();
  }); // window.console.log("Shiny version: ", windowShiny.version);
} // function initShiny()
// Give any deferred iframes a chance to load.


function initDeferredIframes() {
  // TODO-barret; This method uses `window.Shiny`. Could be replaced with `fullShinyObj_.shinyapp?.isConnected()`,
  // but that would not use `window.Shiny`. Is it a problem???
  if ( // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore; Do not want to define `window.Shiny` as a type to discourage usage of `window.Shiny`;
  // Can not expect error when combining with window available Shiny definition
  !window.Shiny || // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore; Do not want to define `window.Shiny` as a type to discourage usage of `window.Shiny`;
  // Can not expect error when combining with window available Shiny definition
  !window.Shiny.shinyapp || // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore; Do not want to define `window.Shiny` as a type to discourage usage of `window.Shiny`;
  // Can not expect error when combining with window available Shiny definition
  !window.Shiny.shinyapp.isConnected()) {
    // If somehow we accidentally call this before the server connection is
    // established, just ignore the call. At the time of this writing it
    // doesn't happen, but it's easy to imagine a later refactoring putting
    // us in this situation and it'd be hard to notice with either manual
    // testing or automated tests, because the only effect is on HTTP request
    // timing. (Update: Actually Aron saw this being called without even
    // window.Shiny being defined, but it was hard to repro.)
    return;
  }

  $(".shiny-frame-deferred").each(function (i, el) {
    var $el = $(el);
    $el.removeClass("shiny-frame-deferred");
    $el.attr("src", $el.attr("data-deferred-src"));
    $el.attr("data-deferred-src", null);
  });
}

export { initShiny };